import { useEffect, useRef, useState } from "react";
import { ReadRecord } from "../../Service/API/ApiService";
import { Button, Table, DatePicker, Flex, Spin, Card, theme } from "antd";
import {
  LoadingOutlined,
  ExportOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate from "../../Components/formatDate";
import logo from "../../Assests/harnetlogo.png";
const headers = [
  { label: "Sl.No", key: "sl_no" },
  { label: "Stock In Date", key: "CreatedDate" },
  { label: "Material Name", key: "type_name" },
  { label: "Quantity", key: "quantity" },
  { label: "Reserved Quantity", key: "reserved_quantity" },
];

function MaterialStockReport() {
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { RangePicker } = DatePicker;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const exportPdf = useRef();

  useEffect(() => {
    if (fromDate && toDate) {
      fetchData(fromDate, toDate);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
      // fetchDataToDownload(fromDate, toDate);
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [fromDate, toDate]);

  const fetchDataToDownloadPDF = () => {
    console.log("downloading data", data);
    setIsLoading(true);
    if (fromDate && toDate) {
      const doc = new jsPDF();
      doc.setFontSize(18); // Set font size for the title
      doc.text("Harnet India Private Ltd.,", 80, 12);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 139); // Dark blue color
      doc.text("ORDER DETAILS :", 15, 15);
      const logoImg = logo;
      doc.addImage(
        logoImg,
        "PNG",
        doc.internal.pageSize.getWidth() - 35,
        3,
        20,
        15
      );

      // Define the styles for the table
      const tableStyles = {
        theme: "grid",
        styles: {
          fontSize: 8, // Adjust the font size here
          halign: "center",
          cellWidth: "wrap",
        },
        margin: { top: 20 },
      };

      // Adjusting the columns to include dataKey and styling
      const tableColumns = columns.map((col) => ({
        ...col,
        dataKey: col.dataIndex,
      }));
      const dataWithSerialNumbers = filteredData.map((row, index) => ({
        ...row,
        serial: index + 1,
      }));

      // Generate the table with the provided styles
      doc.autoTable(tableColumns, dataWithSerialNumbers, tableStyles);

      // Save the PDF
      doc.save(`Material_Stock_Report_${formatDate(new Date())}.pdf`);
      setIsLoading(false);
    } else {
      setDownloadedData([]);
    }
  };

  const fetchDataToDownload = (fromDate = null, toDate = null) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(` SELECT t1.*, t2.other_material_name as type_name
      FROM store t1
      JOIN other_materials t2 ON t1.type_id = t2.id
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND
       t1.type = 2 
       ${dateFilter} ORDER BY t1.id desc`)
        .then((res) => {
          console.log("Fetched data:", res);
          const modifiedData = res.data[0].rows.map((item, index) => {
            return {
              ...item,
              sl_no: index + 1, // Add size based on the conditions
            };
          });
          setDownloadedData(modifiedData);
          setTimeout(() => {
            csvDownloadRef.current.link.click();
          }, 500);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    } else {
      setDownloadedData([]);
    }
  };

  const fetchData = (fromDate = null, toDate = null) => {
    setIsLoading(true);
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(` SELECT t1.*, t2.other_material_name as type_name
      FROM store t1
      JOIN other_materials t2 ON t1.type_id = t2.id
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND
       t1.type = 2 
       ${dateFilter} ORDER BY t1.id desc`)
        .then((res) => {
          console.log("Fetched data:", res);
          setData(res.data[0].rows);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    }
  };

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Stock In Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      align: "center",
      width: 20,
    },
    {
      title: "Other Material",
      dataIndex: "type_name",
      key: "order1",
      align: "center",
      width: 20,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "orderI3",
      align: "center",
      width: 50,
    },
    {
      title: "Reserved Quantity",
      dataIndex: "reserved_quantity",
      key: "orderDate",
      align: "center",
      width: 100,
    },
  ];

  const filteredData = filterStatus
    ? data.filter((item) => item.order_status === filterStatus)
    : data;

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      console.log("From Date:", dates[0]);
      console.log("To Date:", dates[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      fetchData(dates[0], dates[1]);
      //fetchDataToDownload(dates[0], dates[1]);
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      fetchData(null, null);
    }
  };
  // const formatDate = (date) => {
  //     const day = date.getDate().toString().padStart(2, '0');
  //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //     const year = date.getFullYear();
  //     return `${day}-${month}-${year}`;
  //   };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            color: "#333",
            marginRight: "auto",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Other Material Stock
        </p>
        <RangePicker
          value={[fromDate, toDate]}
          onChange={handleDateChange}
          style={{ width: 300 }}
        />
        <CSVLink
          headers={headers}
          data={downloadedData}
          filename={`Material_Stock_Report_${formatDate(new Date())}.csv`}
          className="hidden"
          ref={csvDownloadRef}
          target="_blank"
        />
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(175, 253, 111, 0.26)" }}
          icon={<ExportOutlined style={{ color: "green", fontSize: 24 }} />}
          onClick={() => fetchDataToDownload(fromDate, toDate)}
        >
          Export to Excel
        </Button>
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
          icon={<PrinterOutlined style={{ color: "brown", fontSize: 24 }} />}
          onClick={fetchDataToDownloadPDF}
        >
          Export to PDF
        </Button>
      </div>
      {isLoading ? (
        <LoadingOutlined style={{ marginTop: 20 }}>
          Loading report...
        </LoadingOutlined>
      ) : (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            onChange: (page, pageSize) => {
              setPagination((prevPagination) => ({
                ...prevPagination,
                current: page,
                pageSize: pageSize,
              }));
            },
            total: pagination.total,
          }}
          size="small"
          bordered
          id="orders-table"
          dataSource={filteredData}
          columns={columns}
          locale={{
            emptyText: isLoading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <Card style={{ background: "rgb(240, 240, 240)" }}>
                <SearchOutlined />
                &nbsp; No data available
              </Card>
            ),
          }}
        />
      )}
    </div>
  );
}

export default MaterialStockReport;
