import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './Components/SignIn/SignIn';
import Dashboard from './Pages/Dashboard/Dashboard';
import UserMaster from './Pages/UserMaster/UserMaster';
import Sidebar from './Components/Sidebar/Sidebar';
import Material from './Pages/Masters/Material/Material';
import Jumbo from './Pages/Masters/Jumbo/Jumbo';
import CustomerMaster from './Pages/Masters/Customers/Customers';
import CoreMaster from './Pages/Masters/Core/Core';
import LablMaster from './Pages/Masters/Labl/Labl';
import LocationMaster from './Pages/Masters/Location/Location';
import RmInward from './Pages/RMInward/RmInward'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, Dropdown, Space, Typography } from 'antd';
import Order from './Pages/Order/Order';
import Store from './Pages/Store/Store';
import Production from './Pages/Production/Production';
import Dispatch from './Pages/Dispatch/Dispatch';
import Report from './Pages/Report/Report';
import ProductionReport from './Pages/Report/ProductionReport';
import DispatchReport from './Pages/Report/DispatchReport';
import StocksReport from './Pages/Report/StocksReport';
import CoreStockReport from './Pages/Report/CoreStockReport';
import LabelStockReport from './Pages/Report/LabelStockReport';
import MaterialStockReport from './Pages/Report/MaterialStockReport';
import pageNotFound from './Assests/pageNotFound.png';
import RibbonMaster from './Pages/Masters/Ribbon/Ribbon';
import RibbonStockReport from './Pages/Report/RibbonStokReport';

const { Header, Content } = Layout;
const username = localStorage.getItem("usernameHarnet");
const { Text, Link } = Typography;




function LayOut() {
 
  const sidebarColor = '#333';

  const headerColor = '#444';
  const [collapsed, setCollapsed] = useState(false);
  const [pageTitle, setPageTitle] = useState('');


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    localStorage.removeItem("loginHarnet");
    localStorage.removeItem("usernameHarnet");
    window.location.reload();
  };

 

  return (
 
    <Router>
      <Layout  >
        <Sidebar collapsed={collapsed}/>
        <Layout>

          <Header style={{ padding: 0, background: '#0a194e', display: 'flex', alignItems: 'center' }}>
          {/* <hr className="line" /> */}

            <Button
            

              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                color:'#fff',
              }}
            />
            {/* <Title level={4} >{pageTitle}</Title> */}

            <p style={{color:'white', textTransform: 'uppercase' }}>Harnet India Private Ltd</p>


            <Dropdown overlay={<Menu  >

              <Menu.Item key="logout" onClick={handleLogout} >
                Logout
              </Menu.Item>
            </Menu>}>
              <Button type="text" icon={<UserOutlined style={{ fontSize: '4px', color: '#fff' }} />} style={{ marginLeft: 'auto', color: '#fff' }}>
                Hi,{username.toUpperCase()}

              </Button>
            </Dropdown>

          </Header>
         
          <Content 
         
            style={{
               margin: '16px 10px',
               padding: 10,
               minHeight: '83vh',
               height: '84vh',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              // maxHeight: 'calc(100vh - 50px)',
              
            }}
          
          >
            <Routes  className='pageContent'>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/Masters/usermaster" element={<UserMaster />}></Route>
              <Route exact path="/Masters/Material" element={<Material />}></Route>
              <Route exact path="/Masters/Jumbo" element={<Jumbo />}></Route>
              <Route exact path="/Masters/Customer" element={<CustomerMaster />}></Route>
              <Route exact path="/Masters/Core" element={<CoreMaster />}></Route>
              <Route exact path="/Masters/Label" element={<LablMaster />}></Route>
              <Route exact path="/Masters/Location" element={<LocationMaster />}></Route>
              <Route exact path="/RmInward/RmInward" element={<RmInward />}></Route>
              <Route exact path="/Order/Order" element={<Order />}></Route>
              <Route exact path="/Order/Order/:id" element={<Order />}></Route>
              <Route exact path='/RmInward/Store' element={<Store/>}></Route>
              <Route exact path='/Production/Production' element={<Production/>}></Route>
              <Route exact path='/Dispatch/dispatch' element={<Dispatch/>}></Route>
              <Route exact path='/Reports/orders' element={<Report/>}></Route>
              <Route exact path='/Reports/production' element={<ProductionReport/>}></Route>
              <Route exact path='/Reports/dispatch' element={<DispatchReport/>}></Route>
              <Route exact path='/Reports/stocks' element={<StocksReport/>}></Route>
              <Route exact path='/Reports/stocks/core' element={<CoreStockReport/>}></Route>
              <Route exact path='/Reports/stocks/material' element={<MaterialStockReport/>}></Route>
              <Route exact path='/Reports/stocks/label' element={<LabelStockReport/>}></Route>
              <Route exact path='/Reports/stocks/ribbon' element={<RibbonStockReport/>}></Route>
              <Route exact path='/Masters/ribbon' element={<RibbonMaster/>}></Route>



              <Route exact path="*" element={(
                <>
                  {/* <h1>Error 404</h1>
                  
                  <strong>Page not found</strong> */}
                  <img src={pageNotFound} alt="Page Not Found" height={400} />
                </>
              )}
              />
            </Routes>
          </Content>
          </Layout>
      </Layout>
       

    </Router>


  );
}


export default LayOut;
