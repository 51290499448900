import React, { useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
import {
  BarChartOutlined,
  ShoppingOutlined,
  ContainerOutlined,
  DashboardOutlined,
  UserAddOutlined,
  ToolOutlined,
  ProductFilled,
  CodeSandboxCircleFilled,
  LayoutOutlined,
  GlobalOutlined,
  ContactsOutlined,
  MediumOutlined,
  StockOutlined,
  DotChartOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import logo from "../../Assests/harnetlogo.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GiBandageRoll } from "react-icons/gi";

const { Sider } = Layout;

function Sidebar({ collapsed = false }) {
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);

  const sidebarColor = "#0a194e";

  const handleNavigate = (path, category) => {
    // if (!path.startsWith('/Masters') && !path.startsWith('/RmInward')) {
    //   setOpenKeys([]); // Close any open submenu when navigating to a new page outside "Masters" or "RmInward"
    // } else if (category == 'Masters') {
    //   setOpenKeys(['sub']); // Open the "Masters" submenu when navigating within the "Masters" category
    // } else if (category == 'RmInward') {
    //   setOpenKeys(['2']); // Open the "Inventory" submenu when navigating within the "RmInward" category
    // }
    navigate(path);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const CustomSider = styled(Sider)``;

  return (
    // <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: sidebarColor }}>

    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="demo-logo-vertical" />
      <div className="form-title">
        <div className="svg">
          <p style={{ color: "white", textTransform: "uppercase", padding: 8 }}>
            Harnet
          </p>
        </div>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        //defaultSelectedKeys={['1']}
        //defaultOpenKeys={openKeys}
        //onOpenChange={handleOpenChange}
        // style={{ background: sidebarColor, marginBottom: 20 }}
      >
        <Menu.Item
          key="1"
          icon={<DashboardOutlined />}
          onClick={() => handleNavigate("/dashboard")}
          title="DASHBOARD"
        >
          DASHBOARD
        </Menu.Item>
        <Menu.SubMenu key="sub" icon={<MediumOutlined />} title="MASTERS">
          <Menu.Item
            key="sub1"
            onClick={() => handleNavigate("/Masters/usermaster")}
            icon={<UserAddOutlined />}
          >
            {" "}
            USER
          </Menu.Item>
          <Menu.Item
            key="sub4"
            onClick={() => handleNavigate("/Masters/Customer")}
            icon={<ContactsOutlined />}
          >
            {" "}
            CUSTOMER
          </Menu.Item>
          <Menu.Item
            key="sub2"
            onClick={() => handleNavigate("/Masters/Material")}
            icon={<ToolOutlined />}
          >
            {" "}
            MATERIAL
          </Menu.Item>
          <Menu.Item
            key="sub3"
            onClick={() => handleNavigate("/Masters/Jumbo")}
            icon={<ProductFilled />}
          >
            {" "}
            JUMBO
          </Menu.Item>
          <Menu.Item
            key="sub5"
            onClick={() => handleNavigate("/Masters/Core")}
            icon={<CodeSandboxCircleFilled />}
          >
            {" "}
            CORE
          </Menu.Item>
          <Menu.Item
            key="sub6"
            onClick={() => handleNavigate("/Masters/Label")}
            icon={<LayoutOutlined />}
          >
            {" "}
            LABEL
          </Menu.Item>
          <Menu.Item
            key="sub8"
            onClick={() => handleNavigate("/Masters/ribbon")}
            icon={<GiBandageRoll />}
          >
            {" "}
            RIBBON
          </Menu.Item>
          <Menu.Item
            key="sub7"
            onClick={() => handleNavigate("/Masters/Location")}
            icon={<GlobalOutlined />}
          >
            {" "}
            LOCATION
          </Menu.Item>
        </Menu.SubMenu>
        {/* <Menu.SubMenu key="2" icon={<ContainerOutlined />} title="INVENTORY">
          <Menu.Item key="inventory1" onClick={() => handleNavigate('/RmInward/Store')} icon={<StockOutlined />}>STOCK</Menu.Item> */}
        {/* <Menu.Item key="inventory2" onClick={() => handleNavigate('/RmInward/RmInward', 'RmInward')} icon={<ToolOutlined />} >RM INWARD</Menu.Item> */}
        {/* </Menu.SubMenu> */}
        <Menu.Item
          key="4"
          icon={<ContainerOutlined />}
          onClick={() => handleNavigate("/RmInward/Store")}
          title="STOCKS"
        >
          INVENTORY
        </Menu.Item>

        <Menu.Item
          key="40"
          icon={<ShoppingOutlined />}
          onClick={() => handleNavigate("/Order/Order")}
          title="ORDER"
        >
          ORDERS
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<DotChartOutlined />}
          onClick={() => handleNavigate("/Production/Production")}
        >
          PRODUCTION
        </Menu.Item>
        <Menu.Item
          key="8"
          icon={<CheckOutlined />}
          onClick={() => handleNavigate("/Dispatch/dispatch")}
        >
          DISPATCH
        </Menu.Item>
        <Menu.SubMenu key="sub9" icon={<BarChartOutlined />} title="REPORTS">
          <Menu.Item
            key="5"
            icon={<BarChartOutlined />}
            onClick={() => handleNavigate("/Reports/orders")}
          >
            {" "}
            ORDERS
          </Menu.Item>

          <Menu.Item
            key="12"
            icon={<BarChartOutlined />}
            onClick={() => handleNavigate("/Reports/production")}
          >
            {" "}
            PRODUCTION{" "}
          </Menu.Item>

          <Menu.Item
            key="11"
            icon={<BarChartOutlined />}
            onClick={() => handleNavigate("/Reports/dispatch")}
          >
            {" "}
            DISPATCH
          </Menu.Item>
          <Menu.SubMenu key="90" icon={<BarChartOutlined />} title="STOCKS">
            <Menu.Item
              key="91"
              icon={<BarChartOutlined />}
              onClick={() => handleNavigate("/Reports/stocks/label")}
            >
              {" "}
              <Tooltip placement="right" title="LABEL STOCK">
                LABEL STOCK
              </Tooltip>{" "}
            </Menu.Item>
            <Menu.Item
              key="95"
              icon={<BarChartOutlined />}
              onClick={() => handleNavigate("/Reports/stocks/ribbon")}
            >
               <Tooltip placement="right" title="RIBBON STOCK">
                RIBBON STOCK
              </Tooltip>{" "}
            </Menu.Item>
            <Menu.Item
              key="92"
              icon={<BarChartOutlined />}
              onClick={() => handleNavigate("/Reports/stocks/material")}
            >
               <Tooltip placement="right" title="MATERIAL STOCK">
                MATERIAL STOCK
              </Tooltip>{" "}
            </Menu.Item>
            <Menu.Item
              key="93"
              icon={<BarChartOutlined />}
              onClick={() => handleNavigate("/Reports/stocks")}
            >
               <Tooltip placement="right" title="JUMBO STOCK">
                JUMBO STOCK
              </Tooltip>{" "}
            </Menu.Item>
            <Menu.Item
              key="94"
              icon={<BarChartOutlined />}
              onClick={() => handleNavigate("/Reports/stocks/core")}
            >
               <Tooltip placement="right" title="CORE STOCK">
                CORE STOCK
              </Tooltip>{" "}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu.SubMenu>
      </Menu>
    </Sider>
  );
}

export default Sidebar;
